
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css);
@import url("https://fonts.googleapis.com/css?family=Cabin:400,500,600,700");
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');

/* --- */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");
:root {

  --activeIcon: #a16d12;
  --delay: 0s;
  --background: radial-gradient(ellipse at center, #0a2e38 0%, #000000 70%);
  --body-bg-color: radial-gradient(circle, #1a2049 0%, #13162f 100%);
  --svg-color: #fff;
  --popup-bg-color: #ffa51e;
  --popup-title-color: #192cb7;

    --navItem:  rgb(161, 18, 18);
    --c0: #f8f8f8;
    --c1: #4255d4;
    --c2: #29abe2;
    --c3: #050507;
    --c4: #6665c9;
	--color-text: #fff;
	--color-bg: #fff;
	--color-link: #000;
	--color-info: #000;
	--color-link-hover: var(--c1);
  
  
  --svg-color: #fff;
  --popup-bg-color: #e2e8f0;
  --popup-bg-border: #192cb7;
  --popup-title-color: #192cb7;
  --box-bg-color: #81ac00;
  --box-bg-color: #b5b7ba;

  --bckmodal:#262626;
  --border: #ff006c;

  --card-bg-color:  radial-gradient(circle, #1a2049 0%, #13162f 100%);
  --header-bg-color: radial-gradient(circle, #141834 0%, #13162f 100%);
  --box-shadow-bg-color:  #0e0e23;
  --theme-bg-color: #27292d;
  --border-color: #323336;
  --body-color: #9b9ca7;
  --active-conversation-bg: linear-gradient( to right, rgba(47, 50, 56, 0.54), rgba(238, 242, 244, 0) 100% );
  --msg-hover-bg: rgba(47, 50, 56, 0.5);
  --btn-disabled:rgb(5, 82, 121);
  --chat-text-bg: #383b40;
  --chat-text-color: #b5b7ba;
  --msg-date: #626466;
  --msg-message: var(--msg-date);
  --overlay-bg: linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, #27292d 65%, #27292d 100% );
  --input-bg: #2f3236;
  --chat-header-bg: linear-gradient( to bottom, #27292d 0%, #27292d 78%, rgba(255, 255, 255, 0) 100% );
  --settings-icon-color: #7c7e80;
  --developer-color: var(--border-color);
  --button-bg-color: #393b40;
  --button-color: var(--body-color);
  --input-chat-color: #6f7073;
  --detail-font-color: var(--input-chat-color);

  
 }
 
 
 
 .dark-mode {
  --activeIcon: #12a182;
   
  --background:radial-gradient(ellipse at center, #0a2e38 0%, #000000 70%);
  --body-bg-color: #0e0e23;
  --svg-color: #fff;
  --popup-bg-color: #ffa51e;
  --popup-title-color: #192cb7;

  --card-bg-color:  radial-gradient(circle, #1a2049 0%, #13162f 100%);
  --header-bg-color: radial-gradient(circle, #141834 0%, #13162f 100%);
  --box-shadow-bg-color:  #0e0e23;
  --theme-bg-color: #27292d;
  --border-color: #323336;
  --body-color: #9b9ca7;
  --active-conversation-bg: linear-gradient( to right, rgba(47, 50, 56, 0.54), rgba(238, 242, 244, 0) 100% );
  --msg-hover-bg: rgba(47, 50, 56, 0.5);
  --btn-disabled:rgb(5, 82, 121);
  --chat-text-bg: #383b40;
  --chat-text-color: #b5b7ba;
  --msg-date: #626466;
  --msg-message: var(--msg-date);
  --overlay-bg: linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, #27292d 65%, #27292d 100% );
  --input-bg: #2f3236;
  --chat-header-bg: linear-gradient( to bottom, #27292d 0%, #27292d 78%, rgba(255, 255, 255, 0) 100% );
  --settings-icon-color: #7c7e80;
  --developer-color: var(--border-color);
  --button-bg-color: #393b40;
  --button-color: var(--body-color);
  --input-chat-color: #6f7073;
  --detail-font-color: var(--input-chat-color);
 }


 
* {
  outline: none;
  box-sizing: border-box;
}

.hidden {
  display: none !important;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

img {
  /* max-width: 100%; */
  /* width: 30px;
    height: 30px; */

    /* width: 200px; */
    /* width: 400px;
    height: 80px;
    display: inline; */
}
 
#sidenavbar{
    /* position: fixed; */
/* }
.left-side { */
  width: 6rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  padding: 117px 0 ;
  /* right: 0; */
  /* padding: 117px 0 40px; */
  
  /* -webkit-animation: left 1s var(--delay) both; */
          /* animation: left 1s var(--delay) both; */
          animation: right 1s var(--delay) both;
}
.left-side svg {
  width: 24px;
  cursor: pointer;
}
.left-side svg + svg {
  margin-top: 34px;
}
.left-side svg:last-child {
  margin-top: auto;
}
.left-side svg.active, .left-side svg:hover {
  color: #4255d4;
}


@keyframes right {
  0% {
    transform: translateX(30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@-webkit-keyframes left {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes left {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes top {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes top {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* padding: 25px; */
  overflow: auto;
  /* padding-left: 0; */
  /* padding-left: 11px; */
  /* padding-left: 0; */
  padding: 0 6em;
}

.header {
  /* background: #141834;
  background: radial-gradient(circle, #141834 0%, #13162f 100%);
  background:  var(--header-bg-color); */

  /* box-shadow: 0 16px 12px #0e0e23; */
  /* box-shadow: 0 16px 12px   var(--box-shadow-bg-color); */
  /* --header-bg-color: #29abe2; */

  /* width: 100%;
  padding: 0 30px;
  -webkit-animation: top 1s both;
          animation: top 1s both;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-size: 15px;
  white-space: nowrap;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10; */
  
}
.header:before {
  content: "";
  width: 100%;
  height: 25px;
  position: absolute;
  top: -25px;
  left: 0;
  background-color: #0e0e23;
}
.header-link {
  color: #9b9ca7;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 20px;
  transition: 0.3s;
  border-bottom: 3px solid transparent;
  transition: 0.3s;
}
.header-link svg {
  width: 20px;
  margin-right: 14px;
}
.header-link.active, .header-link:hover {
  background: #11132c;
  border-bottom: 3px solid #4255d4;
}

.logo {
  /* font-family: 'Monofett', cursive; */
/* font-family: 'MuseoModerno', cursive; */
font-family: 'MuseoModerno';
  padding: 20px 50px 20px 0;
  /* font-size: 16px; */

  width: auto;
  font-size: 38px;
  flex-shrink: 0;

  color: var(--theme-color);
  /* color: #e7e8ea; */
}
.logo {
  /* color: var(--theme-color); */
  /* width: 38px; */
 }
.logo-det {
  /* background: #4255d4; */
  background: red;
  padding: 8px;
  margin-left: -2px;
  border-radius: 50%;
  font-size: 15px;
}

.user-info {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: var(--theme-color);

  /* background: green; */
  display: flex;
  align-items: center;
  
  display: flex;

}
.user-info svg {
  width: 20px;
  display: inline;
}
.user-info .profile {
  margin: 0 20px 0 12px;
  width: 18px;
}

.button {
  display: flex;
  align-items: center;
  color: #9b9ca7;
  background: #1a1b3c;
  border: none;
  padding: 2px 12px;
  border-radius: 4px;
  margin-right: 20px;
}
.button svg {
  margin-left: 10px;
  width: 16px;
}

.user-box {
  margin-top: 25px;
  margin-top: 105px;

  display: flex;

  flex-direction: column;
  align-items: center;
  
  /* margin:auto; */
}
.user-box + .user-box {
  /* margin:auto; */
  /* margin-top: 20px; */
  margin-top: 120px;
}
.user-box + .user-box .cards-wrapper {
  margin-left: 0;
  flex-grow: 1;
  max-width: none;
}
.user-box + .user-box .today {
  margin-left: 30px;
}
.user-box + .user-box .cards-header {
  padding: 20px 30px;
  justify-content: space-between;
}
.user-box .cards-header,
.user-box .cards-view {
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-box .cards-header svg,
.user-box .cards-view svg {
  width: 24px;
}
.user-box .cards-header .title,
.user-box .cards-view .title {
  margin: 0 16px;
  font-size: 15px;
}
.user-box .cards-header-date svg,
.user-box .cards-view-date svg {
  width: 30px;
}
.user-box .cards-header .cards-button,
.user-box .cards-view .cards-button {
  background: #15193c;
  padding: 10px 16px;
  margin-right: 0;
}
.user-box .cards-header .cards-button svg,
.user-box .cards-view .cards-button svg {
  width: 16px;
  margin: 0 2px 0 0;
}
.user-box .cards-header .date-wrapper,
.user-box .cards-view .date-wrapper {
  display: flex;
  align-items: center;
  margin: auto;
}
.user-box .cards-header .date-wrapper .title,
.user-box .cards-view .date-wrapper .title {
  margin: 0 16px;
}

.cards-view > svg {
  margin-right: 12px;
}

.today {
  position: relative;
}
.today:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #ef415c;
  bottom: -8px;
  right: 50%;
  border-radius: 50%;
}

.card {
  background: #1a2049;
  /* background: radial-gradient(circle, #1a2049 0%, #13162f 100%); */
  background: var(--card-bg-color);

  padding: 40px 30px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* max-height: 430px; */
  width: 100%;
}
.card .title {
  font-size: 16px;
  font-weight: 500;
}
.card .subtitle {
  font-size: 13px;
  line-height: 1.6em;
}
.card + .card {
  /* margin-left: 20px; */
}

.activity {
  max-width: 480px;
}
.activity .title {
  margin-bottom: 20px;
}
.activity-links {
  display: flex;
  align-items: center;
  margin-top: auto;
  font-size: 15px;
}
.activity-link {
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
}
.activity-link + .activity-link {
  margin-left: 25px;
}
.activity-link + .activity-link:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #ef415c;
  top: -2px;
  right: -8px;
  border-radius: 50%;
}
.activity-link + .activity-link:hover:after {
  content: "";
  position: absolute;
  width: 22px;
  height: 2px;
  background: #4255d4;
  left: 0;
  bottom: 0;
}
.activity-link + .activity-link:hover {
  color: #bebec4;
  -webkit-text-stroke: 0.3px;
}
.activity-link.active {
  color: #bebec4;
  font-weight: 500;
}
.activity-link.active:before {
  content: "";
  position: absolute;
  width: 22px;
  height: 2px;
  background: #4255d4;
  left: 0;
  bottom: 0;
}

.destination {
  display: flex;
  align-items: center;
  margin-top: auto;
}
.destination-card {
  background: #1a1d3a;
  background: linear-gradient(45deg, #1a1d3a 0%, #212752 100%);
  padding: 20px;
  width: 100%;
  border-radius: 6px;
}
.destination-card + .destination-card {
  margin-left: 20px;
  background: #1a1d3a;
  background: linear-gradient(325deg, #1a1d3a 0%, #212752 100%);
}
.destination-profile {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.destination-profile svg {
  width: 18px;
  flex-shrink: 0;
  margin-right: 8px;
}
.destination-length {
  margin-left: auto;
  display: flex;
  align-items: center;
  font-size: 13px;
}
.destination-points {
  margin-top: 30px;
}

.profile-img {
  width: 46px;
  height: 46px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid #bebec4;
  flex-shrink: 0;
}

.point {
  font-size: 14px;
  color: #bebec4;
  font-weight: 500;
}

.sub-point {
  font-size: 13px;
  margin-top: 4px;
}

.discount {
  max-width: 320px;
  width: 100%;
}
.discount .title {
  margin-bottom: 30px;
}
.discount .subtitle {
  margin-bottom: 8px;
}
.discount .subtitle-count {
  font-size: 17px;
  color: #17a98a;
  font-weight: 500;
}
.discount .subtitle-count + .subtitle {
  margin-top: 20px;
}
.discount .subtitle-count.dist {
  color: #e85471;
}
.discount-wrapper {
  display: flex;
}
.discount-chart {
  margin-left: auto;
}
.discount-profile {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.discount-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 0 16px 0 12px;
}
.discount-name {
  font-weight: 500;
  font-size: 15px;
}
.discount-type {
  font-size: 13px;
  margin-top: 4px;
}

.circle {
  width: 100px;
  height: 100px;
  border: 3px solid #5b5f78;
  border-radius: 50%;
  position: relative;
}

.pie {
  position: relative;
  width: 120px;
  height: 120px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}
.pie svg circle {
  fill: transparent;
  stroke: #4356d6;
  stroke-width: 14;
  stroke-dasharray: 275;
  stroke-dashoffset: 235;
  -webkit-animation: pieChart 3.8s linear forwards;
          animation: pieChart 3.8s linear forwards;
}

@-webkit-keyframes pieChart {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes pieChart {
  100% {
    stroke-dashoffset: 0;
  }
}
.counter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #3e50c5;
  font-weight: 500;
}

.offer-button {
  background: #4255d4;
  padding: 14px;
  text-align: center;
  justify-content: center;
  margin-top: auto;
  margin-right: 0;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
}

.cards-header {
  background: #10122b;
  border-radius: 6px 6px 0 0;
  padding: 20px 45px;
  font-size: 14px;
  font-weight: 500;
}
.cards-header-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cards-header-date svg {
  color: #4154d1;
  background: #14183c;
  border-radius: 50%;
  padding: 5px;
  width: 30px;
}
.cards-wrapper {
  margin-left: 20px;
  max-width: 305px;
  width: 100%;
}
.cards-hour {
  font-size: 26px;
}
.cards-hour .am-pm {
  font-size: 15px;
  font-weight: 500;
}
.cards.card {
  border-radius: 0 0 6px 6px;
  padding: 30px 20px;
}
.cards.card svg {
  width: 24px;
  margin-left: auto;
  color: #818394;
}
.cards-head {
  display: flex;
  align-items: center;
}

.degree {
  margin-top: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  color: #818394;
  font-weight: 500;
}
.degree svg {
  width: 24px;
  margin-right: 12px;
}

.items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  font-size: 13px;
  font-weight: 500;
}
.items.numbers {
  margin-top: 0;
}

.item {
  flex: 0 1 calc(100% / 7);
  padding: 10px 5px;
  text-align: center;
}
.item.is-active {
  background: #4255d4;
  border-radius: 50%;
  color: #fff;
}
.item.disable {
  color: #595b5b;
}


/* ARTIST MARKETPALCE  */

.itemCard {
cursor: pointer;
transition: 0.3s;
/* height: 71%;
top: 0;
position: relative; */
}

.itemCardOverlay{
  cursor: pointer;
transition: 0.3s;
/* height: 71%; */
top: 0;
position: relative;
}

.artistMarketplace {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 8px;
}

@media screen and (min-width: 650px){
  .artistMarketplace {
    gap: 16px;
    grid-template-columns: repeat(3, 1fr);
    
  }

}


@media screen and (min-width: 1000px){
  .artistMarketplace {
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    }
}

.itemCardOverlay img{
  width: 100%;
  height: 100%;
  object-fit: contain;

}

.itemCard img{
  width: 100%;
  height: 100%;
  object-fit: contain;

}

.itemCard:hover {
  transform: scale(1.02);
}






.account {
  width: 100%;
  height: 180px;
  margin-top: auto;
  flex-grow: 0;
  position: relative;
  transition: 0.3s;
  cursor: pointer;
  transition: 0.3s;

  /* hack */
  /* width: 30%; */
  width: 27vw;
  min-width: 200px;
  margin: 6px;
  /* margin: 20px; */

}
.account:hover {
  transform: scale(1.02);
}
.account:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  box-shadow: -15px 0 0 0 #ef8741;
  background: #ef415c;
  top: 20px;
  left: 42px;
  border-radius: 50%;
}
.account-wrapper {
  /* max-width: 310px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  align-items: center;
}
.account-profile {
  margin: auto;
  position: relative;
  text-align: center;
  position: relative;
}
.account-profile img {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: left;
     object-position: left;
  border: 3px solid #4255d3;
  padding: 5px;
}
.account-profile .blob {
  position: absolute;
  border-radius: 50%;
  -webkit-animation: fly 5.8s linear infinite alternate;
          animation: fly 5.8s linear infinite alternate;
}
.account-profile .blob:nth-child(1) {
  width: 14px;
  height: 14px;
  top: 25px;
  left: -20px;
  background: #28327a;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.account-profile .blob:nth-child(2) {
  width: 18px;
  height: 18px;
  background: #87344c;
  right: -20px;
  top: -20px;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.account-profile .blob:nth-child(3) {
  width: 12px;
  height: 12px;
  background: #13645b;
  right: -35px;
  top: 50%;
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}
.account-name {
  margin: 20px 0 10px;
}
.account-title {
  font-size: 14px;
}
.account-cash {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 6px;
  padding-top: 16px;
  position: relative;
}
.account-cash:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: #9b9ca7;
  right: 10px;
  border-radius: 50%;
  box-shadow: -10px 0 0 0 #9b9ca7, 10px 0 0 0 #9b9ca7;
  top: 24px;
}
.account-income {
  font-size: 14px;
}
.account-iban {
  margin-top: auto;
  font-weight: 500;
}

.time {
  height: 24px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #9e5924;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.time.is-wait {
  background: #4255d3;
}

@media screen and (max-width: 1500px) {
  
  /* #MUSICUploader{
    margin:auto!important;
} */
.wrapper {
    /* max-width: 1200px; */
  }

  .activity {
    width: 49%;
    max-width: none;
  }

  .discount {
    width: 48%;
    max-width: none;
    height: 100%;
  }

  .first-box {
    flex-wrap: wrap;
  }
  /* .user-box{
    margin: auto;
  }  */
  /* .user-box {
    margin-top: 25px;
    margin:auto;
  } */
  .first-box .cards-wrapper {
    width: 67%;
    max-width: none;
    margin: 20px 0;
  }
  .first-box .cards-wrapper .item.is-active {
    background: none;
    color: inherit;
  }
  .first-box .account-wrapper {
    max-width: none;
    width: calc(33% - 20px);
    margin: 20px 0 20px 20px;
  }

  .second-box {
    flex-wrap: wrap;
  }
  .second-box .cards-wrapper {
    margin-top: 40px;
    width: 66%;
  }

  .transection {
    margin-top: 40px;
    max-width: none;
    width: 30%;
  }
}
@media screen and (max-width: 1200px) {
  .time {
    display: none;
  }
}
@media screen and (max-width: 1060px) {
  .user-info .button,
.user-info .hour {
    /* display: none; */
  }
 

  
}
@media screen and (max-width: 1020px) {
  .user-box .cards-view {
    display: none;
  }

  .user-box .cards-header .cards-button {
    display: none;
  }

  .cards-header-date {
    margin: auto;
  }
}
@media screen and (max-width: 930px) {
  .second-box .cards-wrapper {
    width: 100%;
  }

  .transection {
    width: 100%;
    margin-left: 0;
    height: 100%;
    margin-top: 20px;
  }

  .header-link {
    display: none;
  }

  .user-info .profile {
    margin-right: 0;
  }
}
@media screen and (max-width: 600px) {
  .logo{
    font-size: 2rem;
  }

  .left-side {
    /* display: none; */
    /* width: 1.8rem; */
    /* padding: 2px; */
    /* font-size: 1rem; */
  }
}

  @media screen and (max-width: 850px) {
 
  .activity-card,
.discount {
    width: 100%;
  }

  .user-box .discount {
    margin-left: 0;
    margin-top: 20px;
  }

  .left-side {
    width: 2.8rem;
    padding: 8em 1em;
    
  }
  
  .main-container {
    /* padding-left: 5px; */
    /* background-color: rgba(0, 0, 0, 0.5); */
  }

  #platten{

  }
  .activity-links,
.destination {
    margin-top: 20px;
  }
}
@media screen and (max-width: 720px) {
  .first-box .account-wrapper {
    display: none;
  }

  .first-box .cards-wrapper {
    width: 100%;
    background-color: red;
  }

  .second-box .cards.card {
    overflow-y: auto;
  }
}
@media screen and (max-width: 420px) {
  .destination-card + .destination-card {
    display: none;
  }
}
.user-box > * {
  -webkit-animation: top 1s var(--delay) both;
          animation: top 1s var(--delay) both;
}


/* INSERTION */
.btn-grad {
    padding: 10px;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    border-radius: 10px;
    display: block;
  }
 
  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
.btn-grad {background-image: linear-gradient(to right, var(--c2) 0%, #514A9D  51%, var(--c1)  100%)}
.logout{
    cursor: pointer;
}

.show {
  display: flex;
}

.is-active{

}

 .sidebar-link.is-active {
  color: #fff;
  color: var(--svg-color);
  font-weight: 600;
}



/* EFECTOS DE PELOTITAS */


.author-img {
  width: 52px;
  height: 52px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  padding: 4px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.author-img__wrapper {
  position: relative;
  flex-shrink: 0;
}
.author-img__wrapper svg {
  width: 16px;
  padding: 2px;
  background-color: #fff;
  color: #0daabc;
  border-radius: 50%;
  border: 2px solid #0daabc;
  position: absolute;
  bottom: 5px;
  right: 0;
}
.author-name {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
}
.author-info {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
}
.author-detail {
  margin-left: 16px;
}

/* ******************************* */
/* DARKMODE                        */
/* ******************************* */

.dark-light{
  padding: 0 2em 2px 2px;
  cursor: pointer;
}


  /* @import url("https://fonts.googleapis.com/css?family=Manrope:300,400,500,600,700&display=swap&subset=latin-ext"); */



body {
  /* font-family: "Inter", sans-serif; */
  /* background-color: #93949e; */
  /* color: #9b9ca7!important; */
  display: flex;
  height: 100vh;

  max-width: 1600px;
  /* background-color: #0e0e23; */
  display: flex;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;


  /* background-color: var(--body-bg-color)!important; */
 font-family: var(--body-font)!important;
 color: var(--body-color)!important;

 background: var(--background);
  
 
}

 .user-box{
 color: var(--body-color)!important;

 }

   /* FIXES 2 */
   .alert{ margin-bottom: 0!important; }
   .modal-content{
     background-color: var(--bckmodal);
     box-shadow: 0 0 10px var(--c3);
     border: 1px solid var(--border);
   }
   .modal-backdrop{
    z-index: 0;
   }

svg.darklight  {
  fill: transparent;
  transition: 0.5s;
 }
.darklight {
  /* color: var(--settings-icon-color); */
}
 

.dark-mode .dark-light svg {
  fill: #ffce45;
  stroke: #ffce45;
}

.dark-mode svg.darklight  {
  fill: #ffce45;
  stroke: #ffce45;
}



@media (max-width: 1120px) {
 .detail-area {
  display: none;
 }
}

@media (max-width: 780px) {
 .conversation-area {
  display: none;
 }
 .search-bar {
  margin-left: 0;
  flex-grow: 1;
  input {
   padding-right: 10px;
  }
 }
}



/* HACK swetalert */
.swal2-popup{
  background:  var(--bckmodal)!important;
  /* background:  var(--popup-bg-color)!important; */
  border:  1px solid var(--popup-bg-border)!important;

}
.swal2-title{
  color:  var(--popup-title-color)!important;
}

#langswitch{
  top: 30px;
    position: absolute;
    background: transparent;
}

 
.swal2-file, .swal2-input, .swal2-textarea {
  background: var(--color-link-hover)!important;
}

.swal2-input {
  color: var(--c0)!important;
}

.bg-orange-200 {
  background-color: var(--box-bg-color);

  background-color: transparent!important;
    border: 1px solid blueviolet;
    color: #fff!important;
}


/* quickFIXES */
/* @media screen and (max-width: 800px) { */

@media (max-width: 480px) {
  .header{
    flex-direction: column ;
    padding: 0 30px 10px;
  }
  .header:before {
  display: none;
}
  .user-info{
    margin: auto;
  }
  .main-container {
    padding: 0 10px;
  }
 }






/* -------------- */

.box__title-inner {
	display: block;
	position: relative;
	font-weight: normal;
	text-transform: uppercase;
	/* font-size: 4.15rem; */
	font-size: 2.15rem;
	letter-spacing: 0.15rem;
	line-height: 1.25;
	/* font-family: 'Anton', sans-serif; */
	font-family: 'Monda', sans-serif;

}

.box__title-inner[data-hover] {
	-webkit-text-stroke: 1px var(--c1);
	text-stroke: 1px #000;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
}

.box__title-inner::before {
	content: attr(data-hover);
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	overflow: hidden;
	white-space: nowrap;
	-webkit-text-stroke: 0;
	text-stroke: 0;
	-webkit-text-fill-color: #000;
	text-fill-color: #000;
	color: #000;
	transition: all 0.3s;
}

.grid__item:hover .box__title-inner::before {
	height: 100%;
	width: 100%;
}

.box__text {
	margin: 0;
	position: absolute;
	top: -6rem;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 0.15rem;
	font-size: 1.75rem;
	margin: 0.5rem 1rem;
	font-family: 'Monda', sans-serif;

}

.box__text-inner {
	position: relative;
	display: block;
	border: 6px solid var(--color-text);
	padding: 0.25rem 1.25rem;
}

.box__text-inner--reverse {
	background: var(--color-text);
	color: var(--color-bg);
}

.box__img {
	display: block;
	flex: none;
	margin: 0 auto;
	max-width: 100%;
	filter: grayscale(1);
	transition: filter 0.3s;
	pointer-events: none;
}

.grid__item:hover .box__img:not(.box__img--original) {
	filter: grayscale(0);
}

.box__shadow {
	position: absolute;
	width: 100%;
	height: 100%;
	top: -1rem;
	left: -1rem;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOklEQVQoU43MSwoAMAgD0eT+h7ZYaOlHo7N+DNHL2HAGgBWcyGcKbqTghTL4oQiG6IUpOqFEC5bI4QD8PAoKd9j4XwAAAABJRU5ErkJggg==);


	border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    /* border-image-source: linear-gradient(to left, #743ad5, #d53a9d); */
    border-image-source: linear-gradient(to left, var(--c2), var(--c1));


}

.box__deco {
	font-size: 6rem;
	line-height: 1;
	font-weight: bold;
	position: absolute;
	bottom: -4rem;
	right: -4rem;
	display: none;
}

.box__content {
	position: absolute;
	max-width: 195px;
	font-size: 0.9rem;
	text-align: right;
	/* display: none; */
		display: block;
}

.box__content strong {
	white-space: nowrap;
}

.overlay {
	pointer-events: none;
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	overflow: hidden;
}

.overlay--open {
	pointer-events: auto;
}

.overlay__reveal {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: linear-gradient(to left, var(--c2), var(--c1));
	position: absolute;
	z-index: 100;
	transform: translate3d(100%,0,0);
}

.overlay__item {
	width: 100%;
	height: 100vh;
	/* position: absolute; */
	top: 0;
	left: 0;
	background: linear-gradient(90deg, var(--c3) 0%, var(--c3) 100%);
	opacity: 0;
	display: grid;
	/* grid-template-columns:  auto; */
  position: relative;
  overflow: auto;

    align-items: center;
    padding: 5rem 5vw;
    justify-content: center;
}


.overlay__item {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr  1fr;
  grid-template-areas: 
  "img "
  "contenido";
}
.itemCardOverlay{ grid-area: img; }
.buyButton { grid-area: buy; }
.overlay__content{grid-area: contenido}


/* bigger screeens */
@media screen and (min-width: 650px){

  .overlay__content {
    overflow: auto;
  }
  
  
  .overlay__item {
    overflow: none;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr ;
    grid-template-areas: 
    "img contenido";
  }

}

/* CSSGRID */


.overlay__item .box {
	max-width: 600px;
	max-height: 100%;
	margin: 0;
}

.overlay__item .box__title {
	bottom: 0;
}

.box__img--original {
	max-height: calc(100vh - 10rem);
	filter: none;
}


 
.overlay__close {
	position: absolute;
	top: 0;
	right: 0;
	background: none;
	border: 0;
	margin: 1rem;
	padding: 1rem;
	opacity: 0;
	z-index:1;
}

.overlay__close:focus {
	outline: none;
}

.overlay__content {

  box-sizing: border-box;
  margin: 0px auto;
  min-width: 0px;
  padding: 24px 16px 32px;
  max-width: 1350px;
  width: 100%;
  font-size: 16px;
  /* overflow: auto; */
}

.overlay__content h3 {
	position:static
}

/* 
#imgLogo{
	display: none;
} */


.actions{
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.15rem;
    font-size: 1.35rem;
    margin: 0.5rem 1rem;
	
	z-index: 999;

	/* position: fixed; */
	bottom: 1.5em;

	
}
.actions span:hover{
	color: var(--c2);
}
 



/* BUY BUTTON */

.buyButton {
  display: flex;
  justify-content: center;
  cursor: pointer;
  z-index: 1;

  /* position: fixed; */
  bottom: 5em;
}

.buyButton > a:hover{
	color: var(--c0 ); 
     text-decoration: none;
}

.cta {
    display: flex;
    padding: 10px 45px;
    text-decoration: none;
    /* font-family: 'Poppins', sans-serif; */
	font-family: 'Monda', sans-serif;


    font-size: 40px;
    /* color: white; */
    color: var(--color-text);
    /* background: #6225E6; */
    background: var(--c1);
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(-15deg);
}

.cta:focus {
   outline: none; 
}

.cta:hover {
    transition: 0.5s;
    /* box-shadow: 10px 10px 0 #FBC638; */
    box-shadow: 10px 10px 0 var(--c2);
}

.cta span:nth-child(2) {
    transition: 0.5s;
    margin-right: 0px;
}

.cta:hover  span:nth-child(2) {
    transition: 0.5s;
    margin-right: 45px;
}

 .cta > span {
    transform: skewX(15deg) ;
	font-family: 'Monoton', cursive;
  }

  .cta > span:nth-child(2) {
    width: 20px;
    margin-left: 30px;
    position: relative;
    top: 12%;
  }
  
/**************SVG****************/

path.one {
    transition: 0.4s;
    transform: translateX(-60%);
}

path.two {
    transition: 0.5s;
    transform: translateX(-30%);
}

.cta:hover path.three {
    animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
}


@keyframes color_anim {
    0% {
        fill: white;
    }
    50% {
        fill: #FBC638;
    }
    100% {
        fill: white;
    }
}

/* BUTTON */
.mintButton {
	/* .button { */
	display: inline-block;
	border-radius: 4px;
	background-color: #f4511e;
	border: none;
	color: #FFFFFF;
	text-align: center;
	font-size: 28px;
	padding: 20px;
	width: 200px;
	transition: all 0.5s;
	cursor: pointer;
	margin: 5px;
	z-index: 1;
  }
  
  .button span {
	cursor: pointer;
	display: inline-block;
	position: relative;
	transition: 0.5s;
  }
  
  .button span:after {
	content: '\00bb';
	position: absolute;
	opacity: 0;
	top: 0;
	right: -20px;
	transition: 0.5s;
  }
  
  .button:hover span {
	padding-right: 25px;
  }
  
  .button:hover span:after {
	opacity: 1;
	right: 0;
  }

/* SWEET ALERT 2 FIX */
  .swal2-popup {
	/* font-size: 1.6rem !important; */
  }

  

  /* fake info */
  #fakeinfo{
	/* padding: 5em 3em; */
  }
  #sidebarLogo{
	width: 100%;
    padding: 1em;
  }

  /* gradient button */

.btn-grad {background-image: linear-gradient(to right, var(--c2) 0%, #514A9D  51%, var(--c1)  100%)}
.btn-grad {
   margin: 10px;
   padding: 15px 45px;
   text-align: center;
   text-transform: uppercase;
   transition: 0.5s;
   background-size: 200% auto;
   color: white;            
   box-shadow: 0 0 20px #6600c7;
   border-radius: 10px;
   display: block;
 }

 .scbutton:hover, .btn-grad:hover {
   background-position: right center; /* change the direction of the change here */
   color: #fff;
   text-decoration: none;
 }

 

 /* //logout */
 .logout{
	 cursor: pointer
 }

 .logout:hover {
	 color: var(--c1)
 }

 
 @media screen and (min-width: 650px){
    #logg{
       /* position: absolute;
       right: 8px; */

       display: grid;
       grid-template-columns: auto auto; /* Two columns */
       /* Optional: Add gap property for spacing between items */
       gap: 10px;

     }
  }

/* Add Animation */

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0
    }
    to {
        top: 0;
        opacity: 1
    }
}



/* SWEETALERT LOADER */
.sweet_loader {
	/* width: 140px; */
	/* height: 140px; */
  width: 2em;
  height: 2em;
  margin: 0 -3em;
  margin-bottom: -1em;

	animation-duration: 0.5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	animation-name: ro;
	transform-origin: 50% 50%;
	transform: rotate(0) translate(0,0);
}
@keyframes ro {
	100% {
		transform: rotate(-360deg) translate(0,0);
	}
}

.swal2-top-right {
  min-width: 300px;
}




/* ----- */
:root {
  --c0:#5e44ad;
  --c1: #000;
    --bk: linear-gradient(var(--c1),var(--c0));
    --font: #fff;
    --pico-background-color:red!important;
    --pico-secondary-background:var(--c0)!important; 
    --pico-border-color:var(--c0)!important;
    --pico-form-element-active-border-color:  var(--c0)!important;
    --pico-form-element-focus-color:  var(--c0)!important;
    --pico-primary-border:  var(--c0)!important;
    --primary-color: #f0f1f3;
  }
  
  
   
   
   .dark-mode {
    --main: #2196f3;
    --player: #367;
    --bckmodal:#fff;
    --main: #4f4ee8;
    --primary-color: #f0f1f3;

   }


   #logo{
    font-family: 'Major Mono Display', monospace!important;
    }


  
body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    overflow: hidden;
    width: 100vw; 
    height: 100vh; 
    background: linear-gradient(var(--c1),var(--c0));
    position: absolute;
  }


/* header,header  */
.overlay{
    position: fixed;
    /* width: 100%; */
    height: 100%;
    /* height: calc(100% - 90px); */
    width:  calc(100% - 7em);
    /* height: 100%; */
    overflow-y: scroll;
}

/* SPLASH */

#mglogSearch{
    /* min-width: 300px */
}
/* header 
header img, header  */
#headline,#circular-bg {
      /* position: absolute; */
      /* top: 50%; */
      /* left: 50%;
      transform: translate(-50%, -50%); */
    }


    
    /* header #logo { */
     #logo {
      width: 30px;
      /* background: var(--c0); */
      background: var(--c1);
      padding: 3px;
      position: fixed;
      top: 44%;

    }
   
        /* header #logo span { */
           #logo span {
      display: block;
      cursor: pointer;
      font-size: 16px;
      writing-mode: vertical-rl;
    }

    
     nav {
      /* width: 350px; */
      /* width: 100vw; */

      /* margin: 30px auto 0 auto; */
    }
     nav a {
      font-size: 18px;
      margin-left: 25px;
      position: relative;
    }
     nav a:first-child {
      margin-left: 0;
      position: relative;
    }
    /* #menu-icon {
      float: right;
      margin: -15px 50px 0 0;
    }

    #menu-icon .bar {
      width: 25px;
      height: 2px;
      background: #fff;
      transition: 0.3s cubic-bezier(0.63, -0.18, 0.44, 1.17);
    }
    #menu-icon .bar:first-child {
      margin-bottom: 8px;
    }
    #menu-icon:hover .bar:first-child {
      transform: translateY(5px) rotate(45deg);
    }
    #menu-icon:hover .bar:last-child {
      transform: translateY(-5px) rotate(-45deg);
    } */
    #platten{
      font-size: 100px;
      font-family: Major Mono Display, monospace !important;
      color: var(--font);
    }

    /* header  */
    #platten:before  {
      content: "";
      position: absolute;
      width: 110%;
      height: 60px;
      background: var(--c0);
      /* bottom: -10px; */
      /* top: 101px; */
      top: 155px;
      left: -5%;
      border-radius: 60px;
      z-index: -1;
    }
   
    /* header */
     img {
      width: 500px;
      top: 63%;
    }
    /* header */
    #headline {
      font-size: 30px;
      line-height: 35px;
      /* text-align: left; */
      /* left: 40vw; */
      /* top: 13%; */
      font-family: "Audiowide", sans-serif;
      color: var(--font);
      text-transform: none;
    }
   
    /* header  */
    #headline:after {
      position: absolute;
      /* top: 110%; */
      left: 0;
      font-size: 20px;
      letter-spacing: 1px;
    }

     /* button {
      height: 50px;
      background: var(--c3);
      border: none;
      font-weight: 600;
      border-radius: 5px;
      font-size: 15px;
      color: var(--font);
    } */
    

    @media screen and (max-width: 670px) {
      /* header  */
      #platten {
        font-size: 5rem;
      }
    
    }
    /* header  */
    #plattensmall {
      display: none;
    }
      
    @media screen and (max-width: 600px) {
     
    }

    @media screen and (max-width: 450px) {




    }


    .btn-outline-info {
      color: var(--c3);
      border-color: var(--c3);
    }

 

    /* FIXES 2 */
    .alert{ margin-bottom: 0!important; }
    .modal-content{
      background-color: var(--bckmodal);
      box-shadow: 0 0 10px var(--c3);
      border: 1px solid var(--border);
    }
  
/* 
    #controls,#play, #controls svg, #controls span, #seekHolder,#prev svg,
    .play-button-container{
      opacity: 0!important;
      visibility: hidden!important;
    } */

    /* headMessages.innerHTML = `<div class="alert alert-primary alert-dismissible fade show" > <span data-translate="youareowneroftoken">YOUR ARE OWNER OF UP TOKEN </span><strong>${tid}</strong> <i class="fas fa-trash iconbutton" aria-hidden="true" onclick="event.stopPropagation();burnNFT((this.parentElement))"></i> </div>` */
   #headMessages{
    display: flex;
    position: fixed;
    width: 100vw;
    top: 90px;
   }
   

   /* #tracks a:hover,
 #tracks a:active { */
.track{
background-color:rgba(0,0,0,.1);
color:#fff;
}


  
  .unbutton {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }
  
  .unbutton:focus {
    outline: none;
  }
  
  .frame {
    text-transform: uppercase;
    font-size: 13px;
    position: relative;
    padding: 1.5rem;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'back archive github' 'sponsor sponsor sponsor' ;
    grid-gap: 1.5rem;
    z-index: 6000;
  }
  
  .frame__title {
    grid-area: title;
    font-size: inherit;
    margin: 0;
    font-weight: inherit;
  }
  
  .frame__back {
    grid-area: back;
  }
  
  .frame__archive {
    grid-area: archive;
  }
  
  .frame__github {
    grid-area: github;
  }
  

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: '';
  position: fixed;
  z-index: 10000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5,0.5,1);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
  cursor: pointer;
}

a:hover {
  color: var(--color-link-hover);
  outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:not(.card__image):focus {
  /* Provide a fallback style for browsers
   that don't support :focus-visible */
  outline: none;
  background: lightgrey;
}

a:not(.card__image):focus:not(:focus-visible) {
  /* Remove the focus indicator on mouse-focus for browsers
   that do support :focus-visible */
  background: transparent;
}

a:not(.card__image):focus-visible {
  /* Draw a very noticeable focus style for
   keyboard-focus on browsers that do support
   :focus-visible */
  outline: 2px solid red;
  background: transparent;
}


/* header{
  display: flex;
  align-items: center;
  flex-direction: column;
} */
/* FIXES */
#mainSearch{
  top: 0;
  position: inherit!important;
  width: 80%;
  /* padding: 0 2em 2em; */
}

#splashSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
}


/* metadata card */
  /* Custom styles to enhance the UI */
  #metadataCard {
    margin-top: 2rem;
  }
  .card {
    /* padding: 1rem; */
    /* border: 1px solid #e1e1e1; */
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }
  pre {
    /* background: #f8f9fa; */
    border-radius: 8px;
    padding: 1rem;
    overflow-x: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    height: auto;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin: 0.5rem 0;
    padding: 0.5rem;
    /* background: #f1f3f5; */
    border-radius: 6px;
  }
  .error {
    color: #d9534f;
    margin-top: 1rem;
  }
  #metadataContainer{
    white-space: break-spaces;
    
  }